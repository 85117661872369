import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../../util/reactIntl';
import {
  FieldTextInput,
  Form,
  FieldMultiSelect,
  LocationAutocompleteInputField,
} from '../../../../components';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';
import config from '../../../../config';

import css from './CustomSearchForm.module.css';
import './CustomSelectStyle.css';
import { findConfigForSelectFilter } from '../../../../util/search';
const cleanSearchFromConflictingParams = (
  searchParams,
  sortConfig,
  filterConfig
) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};
class HeroSearchContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQueryParams: props.urlQueryParams,
    };
    this.searchInput = null;
    seen: false;
    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
  }

  togglePop = () => {
    this.setState({
      seen: !this.state.seen,
    });
  };

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;
    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState
        ? currentQueryParam
        : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
        return { ...acc, [paramName]: getInitialValue(paramName) };
      }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { urlQueryParams, history, sortConfig, filterConfig } = this.props;

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = {
          ...urlQueryParams,
          ...prevState.currentQueryParams,
        };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            address,
            bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            sortConfig,
            filterConfig
          );

          history.push(
            createResourceLocatorString(
              'SearchPage',
              routeConfiguration(),
              {},
              search
            )
          );
        }
      };

      this.setState(updater, callback);
    };
  }

  render() {
    return (
      // this is the main coponent that surrounds our complete state
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            handleSubmit,
            searchParams,
            show,
            isMobileLayout,
            values,
            filterConfig,
            handleMake,
            modelOptions,
            handleCategory,
            handleModelKey,
            form
          } = formRenderProps;

          Array.prototype.sortBy = function (p) {
            return this.slice(0).sort(function (a, b) {
              return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
            });
          };

          //Map Toggle CSS
          const cssButton = show ? css.buttonMap : css.button;

          const classes = classNames(rootClassName, className);

          const makeConfig = findConfigForSelectFilter(
            'make',
            filterConfig
          );

          const categoryConfig = findConfigForSelectFilter(
            'category',
            filterConfig
          );

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.searchItemsContainer}>
                <div className={css.keywordSearch}>
                  <div className={css.searchFields}>
                    <div className={css.searchBox}>
                    </div>
                    <div className={css.searchBox}>
                      <label htmlFor="keyword">Keyword</label>
                      <FieldTextInput
                        id={'keywords'}
                        name="keywords"
                        className={css.field}
                        type="text"
                        autoFocus
                      />
                    </div>
                    <div className={css.searchBox} onClick={() => form.change('model', null)}>
                      <label htmlFor="make">Make</label>
                      <FieldMultiSelect
                        options={makeConfig && makeConfig.options}
                        id="make"
                        name="Make"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isRequired={true}
                        classNamePrefix="select"
                        handleChange={handleMake}
                        searchFld={css.searchFld}
                        label={''}
                      />
                    </div>
                    <div className={css.searchBox}>
                      <label htmlFor="model">Model</label>
                      <FieldMultiSelect
                        options={modelOptions}
                        id="model"
                        name="model"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isRequired={true}
                        classNamePrefix="select"
                        handleChange={handleModelKey}
                        searchFld={css.searchFld}
                        label={''}
                      />
                    </div>
                    {/* <div className={css.searchBox}>
                      <label htmlFor="category">Category</label>
                      <FieldMultiSelect
                        options={categoryConfig && categoryConfig.options && categoryConfig.options.sortBy('label')}
                        id="category"
                        name="Category"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isRequired={true}
                        classNamePrefix="select"
                        handleChange={handleCategory}
                        searchFld={css.searchFld}
                        label={''}
                      />
                    </div> */}
                    <div className={css.searchBox}>
                      <label htmlFor="location">Location</label>
                      <LocationAutocompleteInputField
                        rootClassName={isMobileLayout ? css.mobileLocationField : css.locationField}
                        inputClassName={css.desktopInput}
                        iconClassName={css.desktopIcon}
                        predictionsClassName={css.desktopPredictions}
                        validClassName={css.validLocation}
                        name="location"
                        placeholder="Location"
                        useDefaultPredictions={false}
                        format={v => v}
                        valueFromForm={values && values.location}
                      />
                    </div>
                  </div>
                  <button className={cssButton} type="submit">
                    Search
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

HeroSearchContainer.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobileLayout: false,
  filterConfig: config.custom.filters,
};

HeroSearchContainer.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobileLayout: bool,
  intl: intlShape.isRequired,
};

const CustomSearchForm = injectIntl(HeroSearchContainer);

export default CustomSearchForm;
