import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import CustomSearchForm from './CustomSearchForm/CustomSearchForm';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const { rootClassName, className, handleSubmit, modelOptions, handleMake, handleCategory, handleModelKey, isMobileLayout } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          {/* <FormattedMessage id="SectionHero.title" /> */}
          <span>Buy</span> & <span>Sell</span> Car Parts
        </h1>
        <div className={css.searchContainer}>
          <CustomSearchForm
            onSubmit={handleSubmit}
            modelOptions={modelOptions}
            handleMake={handleMake}
            handleCategory={handleCategory}
            handleModelKey={handleModelKey}
            isMobileLayout={isMobileLayout}
            className={css.CustomSearchForm}
          />
        </div>
        {/* <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2> */}
        {/* <NamedLink name="SearchPage" className={css.heroButton}>
          <FormattedMessage id="SectionHero.browseButton" />
        </NamedLink> */}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
