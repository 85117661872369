import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  ListSlider,
  NamedLink,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';

import facebookImage from '../../assets/sneakertimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/sneakertimeTwitter-600x314.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';

import css from './LandingPage.module.css';
import classNames from 'classnames';

import body from '../../assets/bodysquare.jpg';
import braking from '../../assets/braking.jpg';
import engineBay from '../../assets/engineBay.jpg';
import exhaust from '../../assets/exhaust.jpg';
import forcedInduction from '../../assets/forcedInduction.jpg';
import interiorParts from '../../assets/interiorParts.jpg';
import lightAssemblies from '../../assets/lightAssemblies.jpg';
import suspension from '../../assets/suspension.jpg';

import bmw from '../../assets/bmw.png';
import chevy from '../../assets/chevy.png';
import ford from '../../assets/ford.png';
import honda from '../../assets/honda.png';
import lexus from '../../assets/lexus.png';
import nissan from '../../assets/nissan.png';
import toyota from '../../assets/toyota.png';
import subaru from '../../assets/subaru.png';

import brakeKits from '../../assets/brakeKits.jpg';
import exhaustKits from '../../assets/exhaustKits.jpg';
import headlights from '../../assets/headlights.jpg';
import seats from '../../assets/seats.jpg';
import steeringWheels from '../../assets/steeringWheels.jpg';
import tailLights from '../../assets/tailLights.jpg';
import wheels from '../../assets/wheels.jpg';
import wings from '../../assets/wings.jpg';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

const apiToken = 'MoUCrOIKTdLS3lrhs5Qq6QCnB4NuQG04Q7iH0ducJqpVUmhL4IspirmERdjN';
const MAX_IMAGE_MOBILE_SCREEN_WIDTH = 767;

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    listings,
    viewport,
    isAuthenticated,
    currentUser,
    onUpdateProfile,
  } = props;

  const isMobileLayout =
    viewport.width > 0 && viewport.width <= MAX_IMAGE_MOBILE_SCREEN_WIDTH;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage(
    { id: 'LandingPage.schemaTitle' },
    { siteTitle }
  );
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [modelOptions, setModelOptions] = useState([]);
  const [makeKey, setMakeKey] = useState('');
  const [categoryKey, setCategoryKey] = useState('');
  const [modelKey, setModelKey] = useState('');

  const handleSubmit = submittedValues => {
    const { currentSearchParams, history } = props;
    const { keywords, location = {}, category } = submittedValues;
    const { search, selectedPlace = {} } = location;
    const { origin = {}, bounds } = selectedPlace;

    const originMaybe = config.sortSearchByDistance ? { origin } : {};

    const params = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
      search,
      keywords,
    };

    if (makeKey) {
      Object.assign(params, { pub_make: makeKey });
    }

    if (categoryKey) {
      Object.assign(params, { pub_category: categoryKey });
    }

    if (modelKey) {
      Object.assign(params, { pub_model: modelKey });
    }

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        params
      )
    );
  };

  const authorsData =
    listings &&
    listings.map(item => {
      const id = item?.author?.id?.uuid;
      const profileImage =
        item?.author.profileImage?.attributes?.variants['listing-card']?.url;
      return { id, profileImage };
    });

  const popularCategories = [
    {
      key: 'suspension',
      name: 'Suspension',
      image: suspension,
    },
    {
      key: 'braking',
      name: 'Braking',
      image: braking,
    },
    {
      key: 'light-assemblies',
      name: 'Light Assemblies',
      image: lightAssemblies,
    },
    {
      key: 'exhaust',
      name: 'Exhaust',
      image: exhaust,
    },
    {
      key: 'interior-parts',
      name: 'Interior Parts',
      image: interiorParts,
    },
    {
      key: 'forced-induction',
      name: 'Forced Induction',
      image: forcedInduction,
    },
  ];

  const popularMakes = [
    {
      name: 'Honda',
      image: honda,
    },
    {
      name: 'BMW',
      image: bmw,
    },
    {
      name: 'Subaru',
      image: subaru,
    },
    {
      name: 'Nissan',
      image: nissan,
    },
    {
      name: 'Ford',
      image: ford,
    },
    {
      name: 'Toyota',
      image: toyota,
    }
  ];

  const popularParts = [
    {
      key: 'wheels',
      name: 'Wheels',
      image: wheels,
    },
    {
      key: 'braking',
      name: 'Brake Kits',
      image: brakeKits,
    },
    {
      key: 'light-assemblies',
      name: 'Headlights',
      image: headlights,
    },
    {
      key: 'seats',
      name: 'Seats',
      image: seats,
    },
    {
      key: 'steering',
      name: 'Steering Wheels',
      image: steeringWheels,
    },
    {
      key: 'body',
      name: 'Wings',
      image: wings,
    },
  ];

  const handleMake = async values => {
    const makeKey = values && values.key;
    setMakeKey(makeKey);

    if (values && values.label) {
      const modelurl =
        `https://carmakemodeldb.com/api/v1/car-lists/get/all/models/${values &&
          values.label}` +
        '?api_token=' +
        apiToken;

      if (modelurl) {
        const modelResponse = modelurl && (await fetch(modelurl));
        if (modelResponse) {
          const modelData = modelResponse && (await modelResponse.json());
          const modelArr =
            modelData &&
            modelData.map(item =>
              Object.assign({}, { key: item.model, label: item.model })
            );

          setModelOptions(modelArr);
        }
      }
    }
  };

  const handleCategory = values => {
    const categoryKey = values && values.key;
    setCategoryKey(categoryKey);
  };

  const handleModelKey = values => {
    const modelKey = values && values.key;
    setModelKey(modelKey);
  };

  const [bookmarks, setBookmarks] = useState(
    currentUser &&
      currentUser.attributes &&
      currentUser.attributes.profile &&
      currentUser.attributes.profile.publicData &&
      currentUser.attributes.profile.publicData.bookmarks
      ? currentUser.attributes.profile.publicData.bookmarks
      : []
  );

  const handleWishlist = (id, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isAuthenticated && e && id) {
      const routes = routeConfiguration();
      history.push(createResourceLocatorString('LoginPage', routes, {}, {}));
    }

    const index = bookmarks && bookmarks.findIndex(b => b == id);

    if (id) {
      if (index > -1) {
        bookmarks && bookmarks.splice(index, 1);
        const removedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: removedBookmarks,
          },
        };
        onUpdateProfile(profile);
      } else {
        bookmarks.push(id);
        const addedBookmarks = Array.from(new Set(bookmarks));
        const profile = {
          publicData: {
            bookmarks: addedBookmarks,
          },
        };
        onUpdateProfile(profile);
      }
    }
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              handleSubmit={handleSubmit}
              modelOptions={modelOptions}
              handleMake={handleMake}
              handleCategory={handleCategory}
              handleModelKey={handleModelKey}
              isMobileLayout={isMobileLayout}
            />
          </div>
          <div
            className={classNames(css.commonSection, css.recentlyAddedSection)}
          >
            <div className={css.fixWidthContainer}>
              <ListSlider
                title="LandingPage.recentlyAdded"
                viewport={viewport}
                extendedData={'amenities'}
                category={'negocios'}
                listings={listings?.slice(0, 12)}
                queryParams={{}}
                intl={intl}
                sliderHeading={css.sliderHeading}
                isLandingPage={true}
                homeMobileSlider={css.homeMobileSlider}
                bookmarks={bookmarks}
                isAuthenticated={isAuthenticated}
                handleWishlist={handleWishlist}
                recentlyAddedSection={true}
                isMobile={isMobileLayout}
              />
              {/* <SectionFilteredSearches /> */}
            </div>
          </div>
          <div
            className={classNames(css.commonSection, css.topCategorySection)}
          >
            <div className={css.fixWidthContainer}>
              <ListSlider
                title="LandingPage.topCategories"
                viewport={viewport}
                extendedData={'amenities'}
                category={'negocios'}
                listings={listings?.slice(0, 12)}
                queryParams={{}}
                intl={intl}
                sliderHeading={css.sliderHeading}
                isLandingPage={true}
                homeMobileSlider={css.homeMobileSlider}
                isCategories={true}
                popularCategories={popularCategories}
                isMobile={isMobileLayout}
              />
            </div>
          </div>
          <div
            className={classNames(css.commonSection, css.popularMakesSection)}
          >
            <div className={css.fixWidthContainer}>
              <ListSlider
                title="LandingPage.popularMakes"
                viewport={viewport}
                extendedData={'amenities'}
                category={'negocios'}
                listings={listings?.slice(0, 12)}
                queryParams={{}}
                intl={intl}
                sliderHeading={css.sliderHeading}
                isLandingPage={true}
                homeMobileSlider={css.homeMobileSlider}
                isPopular={true}
                popularMakes={popularMakes}
                isMobile={isMobileLayout}
              />
            </div>
          </div>
          <div
            className={classNames(css.commonSection, css.topCategorySection)}
          >
            <div className={css.fixWidthContainer}>
              <ListSlider
                title="LandingPage.popularParts"
                viewport={viewport}
                extendedData={'amenities'}
                category={'negocios'}
                listings={listings?.slice(0, 12)}
                queryParams={{}}
                intl={intl}
                sliderHeading={css.sliderHeading}
                isLandingPage={true}
                homeMobileSlider={css.homeMobileSlider}
                isParts={true}
                popularParts={popularParts}
                isMobile={isMobileLayout}
              />
            </div>
          </div>
          {/* <div
            className={classNames(css.commonSection, css.popularMakesSection)}
          >
            <div className={css.fixWidthContainer}>
              <ListSlider
                title="LandingPage.popularDesigners"
                viewport={viewport}
                extendedData={'amenities'}
                category={'negocios'}
                listings={listings?.slice(0, 12)}
                queryParams={{}}
                intl={intl}
                sliderHeading={css.sliderHeading}
                isLandingPage={true}
                homeMobileSlider={css.homeMobileSlider}
                isAuthors={true}
                authorsData={authorsData}
              />
            </div>
          </div> */}
          <div className={classNames(css.commonSection, css.findMoreServices)}>
            <div className={css.fixWidthContainer}>
              <h2>
              Finish your project car with PartOut
              </h2>
              <p>
              The one-stop shop to buy and sell your parts
              </p>

              <NamedLink name="SearchPage" className={css.seeAll}>
                See All
              </NamedLink>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds } = state.LandingPage;
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    listings: pageListings,
    currentUser,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withViewport,
  injectIntl
)(LandingPageComponent);

export default LandingPage;
